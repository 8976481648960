<template>
  <TabContent>
    <MysticalObject message="Huh, I couldn't find that page... "/>
  </TabContent>
</template>

<script>
import TabContent from '../components/TabContent.vue';
import MysticalObject from '../components/MysticalObject.vue';

export default {
  name: 'NotFound',
  components: {
    TabContent,
    MysticalObject
  },
};
</script>
